.rbc-calendar {
    height: 100vh;
}

.rbc-toolbar .rbc-btn-group:first-child {
    order:3;
}

.rbc-btn-group button {
    padding: 14px;
    border: 1px solid #9F9F9F;
    border-radius: 0;
    text-transform: lowercase;
    margin: 0 5px;
    color: #9F9F9F;
    font-size:16px;
    background-color: #FFFFFF;
}

.rbc-btn-group button.rbc-active {
    background-color: #9F9F9F;
    color: #FFFFFF;
}

.rbc-toolbar .rbc-toolbar-label {
    order: 1;
    display: flex;
    text-align: left;
    align-items: flex-start;
    font-size:35px;
    line-height:35px;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) button:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) button:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.rbc-toolbar .rbc-btn-group:last-child {
    order: 2;
    text-align: left;
    flex-grow: 1;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    box-shadow: none;
}

.rbc-toolbar .rbc-btn-group:first-child {
    display: flex;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(3) {
    order: 2;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(1) {
    order: 3;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #ECECEC;
    border: none;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
    order: 1;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.rbc-toolbar .rbc-btn-group:first-child {
    order:3;
}

.rbc-btn-group button {
    padding: 14px;
    border: 1px solid #9F9F9F;
    border-radius: 0;
    text-transform: lowercase;
    margin: 0 5px;
    color: #9F9F9F;
    font-size:16px;
}

.rbc-btn-group button.rbc-active {
    background-color: #9F9F9F;
    color: #FFFFFF;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) button:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) button:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.rbc-toolbar .rbc-btn-group:last-child {
    order: 2;
    text-align: left;
    flex-grow: 1;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    box-shadow: none;
}

.rbc-toolbar .rbc-btn-group:first-child {
    display: flex;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(3) {
    order: 2;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(1) {
    order: 3;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #ECECEC;
    border: none;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
    order: 1;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.rbc-events-container .rbc-event {
    display: flex !important;
    flex-direction: row !important;
}

.rbc-event-label {
    display: none;
}

.rbc-toolbar .rbc-btn-group button {
    margin-top: 5px;
}

.rbc-time-header-gutter {
    background-color: #FFFFFF;
}

.rbc-time-column {
    background-color: #FFFFFF;
}

.rbc-header {
    background-color: #FFFFFF;
}
.rbc-day-bg {
    background-color: #FFFFFF;
}

.rbc-day-bg.rbc-off-range-bg {
    background: #e6e6e6;
}

.rbc-day-bg.rbc-today {
    background-color: #eaf6ff;
}

@media only screen and (max-width: 768px) {
    .rbc-toolbar .rbc-toolbar-label {
        font-size:25px;
        line-height:25px;
        justify-content: center;
    }
    .rbc-toolbar .rbc-btn-group button {
        padding: 9px;
        margin: 5px auto;
    }
    .rbc-toolbar .rbc-btn-group:last-child {
        text-align: center;
    }
}

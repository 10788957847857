@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import '../components/test/test.scss';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '../styles/calendar-override-styles.css';
@import "~survey-react/survey.css";

.toast-container-error {
  box-shadow: none !important;
  background: transparent !important;
  min-width: 360px;

  .Toastify__close-button {
    display: none !important;
  }
}
.MuiAutocomplete-noOptions {
  display: none;
}
/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {    height: 40px;
    font-family: 'Playfair Display';
    padding: 10px 12px;
    width: 100%;
    background-color: #f5f7fc;
    border-radius: 3px;
    border-bottom: 2px solid #728196;
    transition: box-shadow 150ms ease;
    color: #1f3b63;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
